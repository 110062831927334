.messages {
  .message {
    border: 1px solid #DEDEDE;
    margin-bottom: 10px;
    border-radius: 3px;

    header {
      background: rgba(222, 222, 222, 0.5);
      padding: 10px;
      font-weight: 600;
      ;
      font-size: 16px;
    }

    section {
      background: rgba(255, 255, 255, 0.8);
      padding: 10px;
    }
  }
}