.legends {
  & > div {
    display: inline-block;
    margin-right: 10px;

    span {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      display: inline-block;
    }
  }
}

.progress {
  height: 20px;

  .progress-bar-null {
    background: #e9ecef;
    text-align: center;
    width: 100%;
    color: #333;
  }
}
