.table-striped tbody tr:nth-of-type(even) {
    td.tokens table {
        background: white !important;
    }
}

td.tokens {
    padding: 0px;

    table {
        margin-bottom: 0;

        tr {
            background: transparent !important;
        }
    }

    th,
    td {
        border: 0
    }

    th {
        font-size: 13px;
        vertical-align: middle;
    }

    div {
        margin: 15px;
    }
}