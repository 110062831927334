@import '../variables';

.layout-wrapper {
    --highlight-color: #{$sidebar-secondary-base};
    --highlight-color-light: #{lighten($sidebar-secondary-base, 60%)};

    &.base {
        --highlight-color: #{$sidebar-secondary-base};
        --highlight-color-light: #{lighten($sidebar-secondary-base, 60%)};
    }
    &.custom-blue {
        --highlight-color: #{$sidebar-custom-blue};
        --highlight-color-light: #{lighten($sidebar-custom-blue, 30%)};
    }
    &.birdz-green {
        --highlight-color: #{$birdz-green};
        --highlight-color-light: #{lighten($birdz-green, 30%)};
    }
    &.birdz-orange {
        --highlight-color: #{$birdz-orange};
        --highlight-color-light: #{lighten($birdz-orange, 25%)};
    }
    &.birdz-blue {
        --highlight-color: #{$birdz-blue};
        --highlight-color-light: #{lighten($birdz-blue, 20%)};
    }
    &.boe {
        --highlight-color: #{$boeColor};
    }

    .main-panel {
        position: relative;
        float: right;
        width: calc(100% - 280px);
        background-color: white;
        transition: width 0.3s ease;

        .theme-color {
            color: var(--highlight-color);
        }
        .theme-background {
            background-color: var(--highlight-color);
            transition: background-color 0.3s ease-in-out;
        }
        .theme-background-light {
            background-color: var(--highlight-color-light);
            transition: background-color 0.3s ease-in-out;
        }

        &.expand {
            width: calc(100% - 52px);
        }

        .navbar {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: right;
        }

        .avatar-container {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;
            color: var(--highlight-color);
        }

        .last-login {
            display: block;
            position: absolute;
            right: 12px;
            margin-top: 11px;
            font-style: italic;
            font-size: 13px;
        }

        .page-content {
            display: flex;
            flex-direction: column;
            // padding: 0;
            height: calc(100vh - 67px);
            overflow: hidden;
            // border-top: 1px solid rgba(0, 0, 0, 0.12);

            .inner {
                // margin-top: -1px;
                width: (100%);
                padding-bottom: 16px;
                overflow: auto;
                height: 100%;
            }
        }
    }

    .breadcrumbs {
        background-color: transparent;
        padding: 0;
        margin-top: 2px;

        a {
            color: $sidebar-primary;
            font-size: 0.875rem;
            cursor: default;
            &[href]:hover {
                text-decoration: none;
                cursor: pointer;
            }
        }

        a:after {
            display: block;
            content: '';
            border-bottom: solid 2px var(--highlight-color);
            transform: scaleX(0);
            transform-origin: 0% 50%;
            transition: transform 250ms ease-in-out;
        }
        a[href]:hover:after {
            transform: scaleX(1);
            transform-origin: 0% 50%;
        }
    }
}

@keyframes expand {
    from {
        opacity: 0;
        width: 0;
    }
    to {
        opacity: 1;
        width: 35px; // Largeur finale du bouton
    }
}

@keyframes shrink {
    from {
        opacity: 1;
        width: 35px;
    }
    to {
        opacity: 0;
        width: 0;
    }
}

.animated-button {
    height: 35px;
    overflow: hidden; // Empêche le contenu de dépasser
    animation: expand 0.3s ease-out forwards;
    transition:
        width 0.3s ease-out,
        opacity 0.3s ease-out;
}

.animated-button.exit {
    animation: shrink 0.3s ease-in forwards;
}
