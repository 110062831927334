@import 'variables';

.text-center {
    text-align: center !important;
}

body {
    background-image: url('./bg-light.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 15px;
}

h2 {
    font-size: 30px !important;
    font-weight: 300 !important;
}

h3 {
    margin-bottom: 20px;
    font-size: 24px !important;
    font-weight: 350 !important;
    margin-top: 5px;
}

h4 {
    background: rgba(0, 0, 0, 0.1);
    margin: -10px -10px 10px -10px;
    border-radius: 4px 4px 0px 0px;
    padding: 10px 20px;
    font-size: 20px;
}

.wrapper {
    position: relative;
    top: 0;
    height: 100vh;

    &.wrapper-full-page {
        min-height: 100vh;
        height: auto;
    }
}

.main-panel {
    position: relative;
    float: right;
    width: $sidebar-width;
    background-color: rgba(255, 255, 255, 0.8);

    .navbar {
        .avatar {
            display: inline-block;
            width: 30px;
            height: 30px;
            vertical-align: middle;
            margin-right: 10px;
            margin-top: -10px;
            margin-bottom: -10px;
        }
    }
}

// .page-content {
//     padding: 10px;
// }

.pagination {
    justify-content: flex-end;
}

.toaster {
    position: absolute;
    top: 20px;
    right: 20px;

    &.success {
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    &.error {
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
}

.block {
    background: #fcfcfc;
    padding: 10px;
    border: 1px solid #dee2e6;
    margin-bottom: 15px;
}

.fileInput {
    position: relative;
    overflow: hidden;
    display: inline-block;

    input[type='file'] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

.link {
    cursor: pointer;
}

.link-underline {
    text-decoration: underline;
}

.strong {
    font-weight: bold;
}

.border-left {
    border-left: 1px solid rgba(224, 224, 224, 1);
}

.border-right {
    border-right: 1px solid rgba(224, 224, 224, 1);
}

.lastLogin {
    display: block;
    position: absolute;
    right: 12px;
    margin-top: 11px;
    font-style: italic;
    font-size: 13px;
}

.avatar {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: white;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.btns {
    input[type='button'],
    input[type='submit'],
    button {
        padding: 6px 50px;
    }

    input[type='submit'],
    .btn-primary {
        margin-left: 10px;
    }
}

.modal-80w {
    max-width: 80%;
}

.clickable {
    &:hover {
        text-decoration: underline;
    }

    cursor: pointer;
}

.orderable {
    cursor: pointer;
}

.ordering {
    padding: 3px;
    margin-left: 15px;
    display: inline-block;
}

.orderPosition {
    width: 16px;
    height: 16px;
    font-size: 10px;
}

.pageSize {
    display: inline-block;

    > div {
        z-index: 100;
        display: inline-block;
        width: 80px;
    }
}

.tooltip.show {
    opacity: 1;
}

.tooltip-inner {
    max-width: initial !important;
    background-color: white;
    border: 1px solid #dedede;
    color: black;

    .header {
        background-color: #dedede;
    }
}

.checkedItems {
    font-size: 18px;
    margin-top: -10px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.d-flex {
    display: flex !important;

    &.flex-row {
        flex-direction: row;
    }

    &.flex-column {
        flex-direction: column;
    }

    &.justify-content-center {
        justify-content: center !important;
    }

    &.justify-content-between {
        justify-content: space-between !important;
    }

    &.justify-content-end {
        justify-content: end !important;
    }

    &.align-items-center {
        align-items: center !important;
    }

    &.align-items-base {
        align-items: baseline !important;
    }

    &.align-items-end {
        align-items: end !important;
    }

    &.gap-1 {
        gap: 1rem;
    }
    &.gap-50 {
        gap: 0.5rem;
    }
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.position-relative {
    position: relative !important;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

.p-0 {
    padding: 0 !important;
}

.mr-1 {
    margin-right: 1rem !important;
}
.mr-50 {
    margin-right: 0.5rem !important;
}
.ml-1 {
    margin-left: 1rem !important;
}
.mt-1 {
    margin-top: 1rem !important;
}
.mt-50 {
    margin-top: 0.5rem !important;
}
.mt-25 {
    margin-top: 0.25rem !important;
}
.mb-1 {
    margin-bottom: 1rem !important;
}
.mb-50 {
    margin-bottom: 0.5rem !important;
}
.mb-25 {
    margin-bottom: 0.25rem !important;
}

.p-0 {
    padding: 0 !important;
}
.p-50 {
    padding: 0.5rem !important;
}
.p-1 {
    padding: 1rem !important;
}
.pr-1 {
    padding-right: 1rem !important;
}
.pl-1 {
    padding-left: 1rem !important;
}
.pt-1 {
    padding-top: 1rem !important;
}
.pt-24 {
    padding-top: 24px !important;
}
.pb-1 {
    padding-bottom: 1rem !important;
}

.border-test {
    border: 1px solid red !important;

    &.blue {
        border: 1px solid blue !important;
    }
}

.overflow-hidden {
    overflow: hidden !important;
}

.input-send_date_before,
.input-send_date_after,
.input-date_before,
.input-date_after,
.input-created_at_before,
.input-created_at_after,
.input-campaign__created_at_before,
.input-campaign__created_at_after {
    width: 100% !important;
    input {
        padding: 8.5px 14px;
    }
}
