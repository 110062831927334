.dots-loader {
    display: inline-block;
    clip-path: inset(0 1.7ch 0 0);
    animation: l 0.7s steps(4, jump-none) infinite;
  }
  
  @keyframes l {
    to {
      clip-path: inset(0)
    }
  }
