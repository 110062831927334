@import '../../../../variables';

.purpose-detail {
    th {
        background: #f0f0f0;
    }

    .status {
        font-weight: normal;
        margin-left: 10px;
        background: $primary;
        padding: 1px 10px 3px;
        border-radius: 12px;
        font-size: 10px;
        border: 1px solid #aaa;
    }

    .word-break {
        overflow-wrap: break-word;
        white-space: pre-wrap
    }
}