.modelForm {
    .page-content .block>.block {
        background: #F9F9F9;
        border-radius: 0px
    }

    .block-grey {
        background: #F7F7F7;
    }

    .externalLink-button {
        margin: 5px 20px;
    }

    .select-container.is-invalid {
        border: 1px solid red;
        border-radius: 4px;

        div>div {
            border: 0px;
        }
    }

    .operator {
        width: 500px;
    }

    .help {
        margin-left: 5px;
        cursor: help;
    }



    .header {
        background-color: #DEDEDE;
    }
}