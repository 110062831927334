@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.page-paper {
    opacity: 0;
    animation: fadeIn 0.3s ease-in-out forwards;
}
