.date-picker-range {
    .MuiFormControl-root {
        width: 100%;
        
    }
    .MuiInputBase-root {
        background-color: white;
        outline: 2px solid transparent;
        
        &:not(.Mui-error) {
            &:focus-within {
                outline: 2px solid #2684FF;
                &.Mui-focused {

                    fieldset {
                        border: none
                    }
                }
            }
        }
        input {
            padding: 8px 14px;
        }
    }
}

