.add-token {
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.05);
}

.modal-60w {
  width: 60%;
  max-width: initial;
}

.react-datepicker-popper {
  z-index: 10000;
}