.timewindows {
  .block {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    border-radius: 4px;
  }

  .react-datepicker-wrapper {
    display: inline;

    .react-datepicker__input-container {
      display: inline;

      .hourPicker {
        width: 70px;
      }
    }
  }

  .hourPicker {
    display: inline-block;
    width: 70px;
    margin-right: 30px;
  }

  .addSlot {
    margin-bottom: 10px;
  }

  .blockTitle {
    background: rgb(222, 226, 230);
    padding: 0px 12px;
    margin: -10px;
    margin-bottom: 5px;

    .remove {
      float: right;
      font-size: 18px;
      margin-top: 2px;
    }
  }

  .content {
    padding: 10px;
  }

  .label {
    margin-right: 10px;
    display: inline-block;
    width: 130px;
  }

  .helper-label {
    margin-right: 10px;
  }

  .daySlot {
    margin-top: 10px;
  }

  .is-invalid {
    border: 1px solid red;
    border-radius: 4px;
  }
}