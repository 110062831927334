.diffeditor {
    display: flex;

    &>div {
        flex: 1 1;
    }
}

.send_sms {
    align-items: center;
}

.nav-tabs .nav-link.active.has-error,
.nav-tabs .nav-link.has-error {
    color: #eb1644 !important;
    font-weight: bold;
}

*[role=button] {
    cursor: pointer;
}