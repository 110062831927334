.statuses {
    text-align: center;
    font-weight: 600;
}

.status {
    width: 75px;
    border-radius: 3px;
    padding: 4px;
    color: white;
    margin: auto;
    margin-top: 5px;

    &.ok {
        background: rgb(92, 184, 92);
    }

    &.error {
        background: rgb(217, 83, 79);
    }
}

h4{
    background:initial;
    margin-bottom: 10px;
}

button.checkStatus {
    float: right;
  }