.parameters {
    border: 1px solid transparent;
    padding: 5px;

    &.has-error {
        border-color: #dc3545;
        border-radius: 6px;
    }

    h2 {
        font-size: 18px;
        margin: 10px;
    }

    .level {
        &-2 {
            margin: 10px 0 0 15px;
        }
    }

    .field>div {
        &.disabled {
            color: #cdcdcd;

            .field-input>* {
                cursor: not-allowed !important;
            }
        }

        .field-input {
            padding: 5px;
            border: 1px solid transparent;
            border-radius: 5px;
            position: relative;

            &.has-error {
                border-color: #dc3545;
            }
        }

        .field-label {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 5px;
        }
    }

    .bitmask {
        display: flex;
        align-items: flex-end;

        >div {
            width: 40px;
            font-size: 10px;
            margin-right: 10px;
            text-align: center;

            .bit {
                box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
                border: 1px solid #cdcdcd;
                display: block;
                text-align: center;
                width: 30px;
                height: 30px;
                padding-top: 1px;
                cursor: pointer;
                font-size: initial;
                margin-top: 10px;
            }
        }
    }

    input[type="range"] {
        width: calc(100% - 40px);
    }

    input[type="checkbox"] {
        vertical-align: middle;
    }

}