@keyframes fadeIn {
    from {
        opacity: 0;
        // transform: translateY(-10px);
    }
    to {
        opacity: 1;
        // transform: translateY(0);
    }
}

.table-container {
    opacity: 0;
    animation: fadeIn 0.5s ease-in-out forwards;

    .transitioned {
        opacity: 0;
        animation: fadeIn 0.3s ease-in forwards;
    }

    .table-row {
        transition:
            background-color 0.2s ease-in-out;
            // box-shadow 0.3s ease-in-out;
        &:hover {
            background-color: rgba(224, 224, 224, 0.2);
            // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05); /* Ombre en bas */
        }
        &.selectable {
            cursor: pointer;
        }
        &.selected {
            background-color: rgba(224, 224, 224, 0.5);
        }
    }
}
